import axios from "@axios";
import getHeader from "@core/utils/auth";
import URL_API from "@core/utils/env";
import noticeEmail from "@/@core/utils/email/emails/notice.js";
import taskEmail from "@/@core/utils/email/emails/task.js";
import store from "@/store";

export default async function sendEmail(type, obj) {
  var userData = store.state.user.userData;
  var paramData = store.state.user.paramData;
  /// Variables
  var preHeader,
    header,
    body = "";

  if (paramData.sendEmail) {
    /// Users
    var allUsers = [];
    await axios
      .get(
        `${URL_API}user/list?page=0&sort=id,${paramData.listSort}&size=9999`,
        {
          headers: getHeader(userData),
        }
      )
      .then((response) => {
        for (let i = 0; i < response.data.content.length; i++) {
          allUsers.push(response.data.content[i]);
        }
      });

    if (type == "notice") {
      preHeader = "Comunicado BRXMIND";
      header = "Comunicado";
      body = "Você possui um novo comunicado no portal do <b>BRXMIND</b>:";

      if (obj.allUsers == true) {
        for (var i = 0; i < allUsers.length; i++) {
          axios.post(`${URL_API}sending-email-ordinary/${userData.tenant}`, {
            ownerRef: "Braxxy",
            emailFrom: "brxmind@braxxy.com.br",
            emailTo: allUsers[i].email,
            subject: "BRXMIND - [Comunicado]",
            text: noticeEmail(body, obj.notice, obj.title),
          });
        }
      } else {
        var users = [];
        for (var i = 0; i < allUsers.length; i++) {
          for (var j = 0; j < obj.users.length; j++) {
            if (allUsers[i].id == obj.users[j]) {
              users.push(allUsers[i].email);
            }
          }
        }

        for (var i = 0; i < users.length; i++) {
          axios.post(`${URL_API}sending-email-ordinary/${userData.tenant}`, {
            ownerRef: "Braxxy",
            emailFrom: "brxmind@braxxy.com.br",
            emailTo: users[i],
            subject: "BRXMIND - [Comunicado]",
            text: noticeEmail(body, obj.notice, obj.title),
          });
        }
      }
    }

    if (type == "task") {
      preHeader = "Tarefa BRXMIND";
      header = "Tarefa";
      body = "Você possui uma nova tarefa no portal do <b>BRXMIND</b>:";

      var emails = [];

      for (var i = 0; i < allUsers.length; i++) {
        for (var j = 0; j < obj.members.length; j++) {
          if (allUsers[i].id === obj.members[j]) {
            emails.push(allUsers[i].email);
          }
        }
      }

      for (var i = 0; i < emails.length; i++) {
        axios.post(`${URL_API}sending-email-ordinary/${userData.tenant}`, {
          ownerRef: "Braxxy",
          emailFrom: "brxmind@braxxy.com.br",
          emailTo: emails[i],
          subject: "BRXMIND - [Tarefa]",
          text: taskEmail(body, obj.subject, obj.description, obj.projectName),
        });
      }
    }

    if (type == "password") {
    }

    if (type == "register") {
    }
  }
}
